
import { Component, Vue } from "vue-property-decorator";
import Decretos from "@/components/Municipalidad/Decretos.vue";

@Component({
  components: {
    Decretos
  }
})
export default class DecretosView extends Vue {}
