
import { Component, Vue, Watch } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";

@Component({
  components: {}
})
export default class Organizaciones extends Vue {
  private mediaUrl = process.env.VUE_APP_MEDIA_URL;
  private nombreWeb: any = "";
  private textoBuscar = "";
  private documentos: any = [];
  private documentosTemporal: any = [];
  private filteredDocumentos: any = null;

  @Watch("textoBuscar")
  private selectTextoBuscar(newValue: any) {
    this.buscar();
  }

  private mounted() {
    document.title = "Ley 21.146 - Municipalidad de Maipú";
    this.getOrganizaciones();
  }

  private vaciarInput() {
    this.textoBuscar = "";
    this.buscar();
  }
  private buscar() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.documentosTemporal = JSON.parse(JSON.stringify(this.documentos));
    let needle = this.textoBuscar.trim().toLowerCase();
    if (needle == "") {
      this.filteredDocumentos = JSON.parse(JSON.stringify(this.documentos));
    } else {
      this.filteredDocumentos = [];
      for (let i = 0; i < this.documentosTemporal.length; i++) {
        var mes = this.documentosTemporal[i];
        let tempMes = mes.organizaciones.filter(
          doc => doc.nombre.toLowerCase().indexOf(needle) > -1
        );
        if (tempMes.length > 0) {
          mes.organizaciones = tempMes;
          this.filteredDocumentos.push(mes);
        }
      }
    }
    this.$q.loading.hide();
  }

  private getOrganizaciones() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("organizaciones")
      .then((res: any) => {
        // this.documentos = res.data;
        for (let i = 0; i < res.data.length; i++) {
          if (
            res.data[i].organizaciones &&
            res.data[i].organizaciones.length > 0
          ) {
            for (let j = 0; j < res.data[i].organizaciones.length; j++) {
              if (
                res.data[i].organizaciones[j].link &&
                res.data[i].organizaciones[j].link != ""
              ) {
                res.data[i].organizaciones[j].link =
                  process.env.VUE_APP_MEDIA_URL + "/" +
                  res.data[i].organizaciones[j].link;
              } else {
                res.data[i].organizaciones[j].link = "#";
              }
            }
          }
          this.documentos.push(res.data[i]);
        }
        this.filteredDocumentos = [...this.documentos];
        this.documentosTemporal = [...this.documentos];
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }
  private labelMes(item) {
    let response = "";
    if (item) {
      if (item.mes.split("-")[1] == "01") {
        response = "Enero";
      } else if (item.mes.split("-")[1] == "02") {
        response = "Febrero";
      } else if (item.mes.split("-")[1] == "03") {
        response = "Marzo";
      } else if (item.mes.split("-")[1] == "04") {
        response = "Abril";
      } else if (item.mes.split("-")[1] == "05") {
        response = "Mayo";
      } else if (item.mes.split("-")[1] == "06") {
        response = "Junio";
      } else if (item.mes.split("-")[1] == "07") {
        response = "Julio";
      } else if (item.mes.split("-")[1] == "08") {
        response = "Agosto";
      } else if (item.mes.split("-")[1] == "09") {
        response = "Septiembre";
      } else if (item.mes.split("-")[1] == "10") {
        response = "Octubre";
      } else if (item.mes.split("-")[1] == "11") {
        response = "Noviembre";
      } else if (item.mes.split("-")[1] == "12") {
        response = "Diciembre";
      }
      if (item.mes == "LEY") {
        return item.nombre;
      }
      return response + " " + item.mes.split("-")[0];
    }
  }
  private toLink(item: any) {
    if (item && item != "") {
      window.open(item, "_blank");
    } else {
      return;
    }
  }
}
